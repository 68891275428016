@import "./scss/fontawesome/fontawesome.scss";

@import "./scss/fontawesome/solid.scss";
@import "./scss/fontawesome/brands.scss";

$music-font-family: "MuseJazzText", san-serif;
$text-font-family: "Chalkboard", "Noteworthy", san-serif;


@font-face {
  font-family: "MuseJazzText";
  src: 
    url("./fonts/MuseJazzText.otf") format("opentype");
}

body {
  background-color: #555555;
}

.App.container-fluid {
  padding: 0;
}

.navbar, .navbar-brand, .nav-item, .dropdown-toggle, .nav-link {
  background-color: #222222;
  color: floralwhite;
}

.nav-link {
  padding-top: 10px;
}

.nav-link:focus {
  color: floralwhite;
}

.nav-link:hover {
  text-decoration: underline;
}

.navbar-brand {
  font-family: $music-font-family;
  font-size: 24px;
}

.flashcard {
  width: min(450px, 100%);
  height: min(600px, calc(400vw / 3));
  margin: min(10vh, 10%) auto auto auto;
  font-family: $text-font-family;
  font-size: 24pt;
  perspective: 1000px;
}

.flashcard-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flashcard-front, .flashcard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template: repeat(5, 1fr) / 1fr;
  -webkit-backface-visibility: hidden; /* Safari */
  -webkit-transform: translate3d(0,0,0);
  backface-visibility: hidden;
  border-radius: 5px;
}

.correct {
  font-size: 36pt;
}

.flashcard-front {
  color: floralwhite;
  background-color: steelblue;
  border: solid 3px white;
  .correct {
    font-size: 36pt;
  }
}

.flashcard-back {
  transform: rotateY(180deg);
  color: black;
  background-color: white;
  border: solid 3px black;
}

.flashcard-rotate {
  transform: rotateY(180deg);
}

.key-center {
  color: #5f5f5f;
  font-size: 18pt;
  .key-name {
    font-family: $music-font-family;
  }
}

.chord-symbol {
  font-family: $music-font-family;
  font-size: 72pt;

  sup {
    font-size: 48pt;
    position: relative;
    top: -0.2em;
    vertical-align: super;
  }

  sub {
    font-size: 48pt;
    position: relative;
    left: -0.4em;
    top: -0.2em;
    vertical-align: sub;
  }
}

.chord-notes, .mode-characteristic {
  font-family: $music-font-family;
  font-size: 24pt;
}

